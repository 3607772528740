/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import { useGLTF } from '@react-three/drei';
import { useMemo } from 'react';
import * as THREE from 'three';
import { GLTF } from 'three-stdlib';

type GLTFResult = GLTF & {
  nodes: {
    Tree_2_Tree_2Mat_0: THREE.Mesh;
    Tree_3_Tree_3Mat_0: THREE.Mesh;
    Tree_4_Tree_4Mat_0: THREE.Mesh;
  };
  materials: {
    Tree_2Mat: THREE.MeshStandardMaterial;
    Tree_3Mat: THREE.MeshStandardMaterial;
    Tree_4Mat: THREE.MeshStandardMaterial;
  };
};

export function PalmTree(props: { [key: string]: any }) {
  const { nodes, materials } = useGLTF('3d/PalmTree.glb') as GLTFResult;

  const treeMat2 = useMemo(() => {
    const material = materials.Tree_2Mat;
    material.color.setHex(0x000000);
    material.roughness = 0;
    return material;
  }, []);

  const treeMat3 = useMemo(() => {
    return new THREE.MeshBasicMaterial({
      color: 0x000000,
    });
  }, []);

  return (
    <group {...props} dispose={null}>
      <group scale={0.01}>
        <mesh
          geometry={nodes.Tree_2_Tree_2Mat_0.geometry}
          material={treeMat2}
        />
        <mesh
          geometry={nodes.Tree_3_Tree_3Mat_0.geometry}
          material={treeMat3}
        />
        <mesh
          geometry={nodes.Tree_4_Tree_4Mat_0.geometry}
          material={treeMat3}
        />
      </group>
    </group>
  );
}

useGLTF.preload('3d/PalmTree.glb');
