'use client';

import { WalkmanIntro } from '@/3d/WalkmanIntro';
import { useWalkmanStore } from '@/3d/useWalkmanState';
import { Reveal } from '@/ui';
import { useState } from 'react';
import styled from 'styled-components';
import { Scene } from '../../3d/Scene';
import { AudioPlayer } from '../../components/AudioPlayer/AudioPlayer';
import { HeartRateContextualPopup } from '../../components/HeartRateContextualPopup/HeartRateContextualPopup';
import { Letter } from '../../components/Letter';
import { LocationContextualPopup } from '../../components/LocationContextualPopup/LocationContextualPopup';

export const Index = () => {
  const { isDisappeared: isIntroFinished } = useWalkmanStore();

  const [isZoomedIn, setIsZoomedIn] = useState(false);

  return (
    <>
      {isIntroFinished ? (
        <>
          <SceneReveal activeOpacity={0.75} inactiveOpacity={0.5} spread={0}>
            <Scene isZoomedIn={isZoomedIn} />
          </SceneReveal>

          <Letter />

          <IndicatorsContainer>
            <div
              style={{
                position: 'absolute',
                top: 24,
                left: 0,
                transform: 'skew(0deg, 0.01deg) rotateY(8deg)',
                pointerEvents: 'all',
              }}
            >
              <LocationContextualPopup onOpenChange={setIsZoomedIn} />
            </div>

            <div
              style={{
                position: 'absolute',
                right: 32,
                top: 24,
                transform: 'skew(0, -0.01deg) rotateY(-8deg)',
                pointerEvents: 'all',
              }}
            >
              <HeartRateContextualPopup />
            </div>

            <div
              style={{
                position: 'absolute',
                bottom: 16,
                left: 16,
                transform: 'skew(0, -0.01deg) rotateY(8deg)',
                pointerEvents: 'all',
              }}
            >
              <AudioPlayer />
            </div>
          </IndicatorsContainer>
        </>
      ) : (
        <WalkmanIntro />
      )}
    </>
  );
};

const IndicatorsContainer = styled.div`
  position: absolute;
  perspective: 1200px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;

  @media (max-width: 480px) {
    perspective: 4000px;
  }
`;

// styled reveal into SceneReveal
const SceneReveal = styled(Reveal)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
