'use client';

import { roundToNearestMinutes } from 'date-fns';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';

export interface Calendar {
  kind: string;
  etag: string;
  summary: string;
  updated: string;
  timeZone: string;
  accessRole: string; // 'reader'
  defaultReminders: any[];
  nextSyncToken: string;
  items: CalendarEvent[];
}

export interface CalendarEvent {
  kind: string;
  etag: string;
  id: string;
  status: string; // 'confirmed'
  htmlLink: string;
  created: string;
  updated: string;
  summary: string;
  creator: { email: string };
  organizer: {
    email: string;
    displayName: string;
    self: boolean;
  };
  start: {
    dateTime: string;
    timeZone: string;
  };
  end: {
    dateTime: string;
    timeZone: string;
  };
  recurrence?: string[];
  iCalUID: string;
  sequence: number;
  eventType: string; // 'default'
  description?: string;
  location?: string;
}

export const getGoogleCalendarUrl = () => {
  const currentDate = new Date();
  const calendarId = 'o7fuj1odginv2mhkmpibbinhv8@group.calendar.google.com';
  const accessKey = 'AIzaSyAuenQDlvNzDepv6kyNPoQ2SDLZ_ltLVfs';

  /**
   * Rounding the time to nearest 5 minutes to be able to cache the request
   */
  const timeMin = roundToNearestMinutes(currentDate, {
    nearestTo: 5,
  });
  const timeMax = new Date(timeMin.getTime() + 1000 * 60);

  return `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events?key=${accessKey}&timeMin=${timeMin.toISOString()}&timeMax=${timeMax.toISOString()}`;
};

export const getCurrentCalendar = async () => {
  const calendar = (await fetch(getGoogleCalendarUrl()).then(response =>
    response.json()
  )) as Calendar;

  /**
   * First event with summary is the current event
   */
  const currentEvent =
    calendar.items.find(({ summary }) => summary && summary.length > 0) || null;

  /**
   * First event with location is location
   */
  const currentLocation =
    calendar.items.find(({ location }) => location && location.length > 0)
      ?.location || 'Barcelona, Spain';

  const unrestrictedGoogleGeocodingApiKey =
    'AIzaSyBJTxZgaY2ocPxnpG-qy6d5kOPPrY-Ei9g';

  /**
   * Get long & lat from Google GeoCoding API
   */
  const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${currentLocation}&key=${unrestrictedGoogleGeocodingApiKey}`;
  const cachedResult = localStorage.getItem(`fetch:${url}`);

  const currentLocationLongLat = cachedResult
    ? JSON.parse(cachedResult)
    : currentLocation
    ? await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${currentLocation}&key=${unrestrictedGoogleGeocodingApiKey}`
      )
        .then(response => response.json())
        .then(({ results }) => {
          const { location } = results[0].geometry;
          localStorage.setItem(`fetch:${url}`, JSON.stringify(location));
          return location;
        })
    : null;

  return {
    currentEvent,
    currentLocation: {
      title: currentLocation || 'Barcelona',
      long: currentLocationLongLat?.lng,
      lat: currentLocationLongLat?.lat,
    },
  };
};

export interface CalendarContext {
  currentCalendarEvent?: CalendarEvent | null;
  currentCalendarLocation?: {
    title: string;
    long: number;
    lat: number;
  } | null;
}

const CalendarContext = createContext<CalendarContext>({
  currentCalendarEvent: null,
  currentCalendarLocation: null,
});

export const CalendarProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [
    currentCalendarEvent,
    setCurrentCalendarEvent,
  ] = useState<CalendarEvent | null>();
  const [currentCalendarLocation, setCurrentCalendarLocation] = useState<{
    title: string;
    long: number;
    lat: number;
  } | null>();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchCurrentCalendarEvent = async () => {
      /**
       * First render should have loading as false so we can
       * show some default status in SSR before JS kicks in.
       */
      setLoading(true);

      (async () => {
        const { currentEvent, currentLocation } = await getCurrentCalendar();
        setCurrentCalendarEvent(currentEvent);
        setCurrentCalendarLocation(currentLocation);
        setLoading(false);
      })();
    };

    fetchCurrentCalendarEvent();

    const interval = setInterval(() => {
      fetchCurrentCalendarEvent();
    }, 1000 * 60); // every 1 minute

    return () => {
      clearInterval(interval);
    };
  }, []);

  const value = useMemo(() => {
    return { currentCalendarEvent, currentCalendarLocation, loading };
  }, [currentCalendarEvent, currentCalendarLocation, loading]);

  return (
    <CalendarContext.Provider value={value}>
      {children}
    </CalendarContext.Provider>
  );
};

export const useCalendar = () => {
  return useContext(CalendarContext);
};
