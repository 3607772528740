import styled, { keyframes } from 'styled-components';
import { useHealth } from '../../hooks/useHealth';
import { Flex } from '../../ui';
import { ECGChart } from '../ECGChart/ECGChart';
import { HeartRateIndicator } from '../HeartRateIndicator/HeartRateIndicator';

export const HeartRateContextualPopupHandle = () => {
  const { heartRate } = useHealth();

  return (
    <Root alignItems="center">
      <ECGChartOffset>
        <ECGChart heartRate={heartRate} />
      </ECGChartOffset>

      <HeartRate>
        <HeartRateIndicator
          heartRate={heartRate}
          size={20}
          fillOpacity={0.25}
        />
      </HeartRate>
    </Root>
  );
};

const Root = styled(Flex)`
  padding: 8px 16px;
  position: relative;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: linear-gradient(
      to right,
      transparent,
      ${({ theme }) => theme.accent},
      ${({ theme }) => theme.accent},
      transparent
    );
  }

  &::after {
    bottom: 0;
    top: auto;
  }
`;

const heartRateFadeIn = keyframes`
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const HeartRate = styled.div`
  opacity: 0;
  animation: ${heartRateFadeIn} 0.5s ease-in-out;
  animation-delay: 2.5s;
  animation-fill-mode: forwards;

  filter: url(#turbulence);
`;

const ECGChartOffset = styled.div`
  position: relative;
  top: 8px;
  left: -16px;
`;
