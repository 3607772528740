import { useControls } from 'leva';
import { rgba } from 'polished';
import { useEffect, useRef, useState } from 'react';
import styled, { useTheme } from 'styled-components';

export const AudioPlayer = () => {
  const { width, height } = useControls('audio', {
    width: { value: 120, min: 1, max: 1000 },
    height: { value: 32, min: 1, max: 1000 },
  });

  const theme = useTheme();
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioContext, setAudioContext] = useState<AudioContext | null>(null);
  const [analyser, setAnalyser] = useState<AnalyserNode | null>(null);

  const audioRef = useRef<HTMLAudioElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const animationRef = useRef<number | null>(null);

  const play = () => {
    if (!audioRef.current) return;

    audioRef.current.play();
    setIsPlaying(true);
  };

  const pause = () => {
    if (!audioRef.current) return;

    audioRef.current.pause();
    setIsPlaying(false);
  };

  const togglePlay = () => {
    if (isPlaying) {
      pause();
    } else {
      play();
    }
  };

  /* On first render, start playing */
  useEffect(() => {
    play();
  }, []);

  const setupAudio = () => {
    const audio = audioRef.current;
    const canvas = canvasRef.current;
    if (!canvas || !audio) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    // Set up Web Audio API for visualization
    const newAudioContext = new AudioContext();
    const newAnalyser = newAudioContext.createAnalyser();
    newAnalyser.fftSize = 2048;

    const source = newAudioContext.createMediaElementSource(audio);
    source.connect(newAnalyser);
    newAnalyser.connect(newAudioContext.destination);

    setAudioContext(newAudioContext);
    setAnalyser(newAnalyser);

    const devicePixelRatio = window.devicePixelRatio || 1;
    canvas.width = width * devicePixelRatio;
    canvas.height = height * devicePixelRatio;
    canvas.style.width = `${width}px`;
    canvas.style.height = `${height}px`;
    ctx.scale(devicePixelRatio, devicePixelRatio);
  };

  // Set up audio context on first play
  useEffect(() => {
    if (isPlaying && !audioContext && !analyser) {
      setupAudio();
    }
  }, [isPlaying, audioContext, analyser, width, height]);

  // Handle the animation
  useEffect(() => {
    if (!analyser || !isPlaying) return;

    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    // Draw the waveform
    const drawWaveform = () => {
      analyser.getByteTimeDomainData(dataArray);

      ctx.clearRect(0, 0, width, height);

      ctx.lineWidth = 1;
      ctx.strokeStyle = rgba(theme.foreground, 0.5);
      ctx.beginPath();

      const sliceWidth = (width * 1.0) / bufferLength;
      let x = 0;

      for (let i = 0; i < bufferLength; i++) {
        const v = dataArray[i] / 128.0;
        const y = (v * height) / 2;

        if (i === 0) {
          ctx.moveTo(x, y);
        } else {
          ctx.lineTo(x, y);
        }

        x += sliceWidth;
      }

      ctx.lineTo(width, height / 2);
      ctx.stroke();

      animationRef.current = requestAnimationFrame(drawWaveform);
    };

    drawWaveform();

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [isPlaying, analyser, width, height, theme]);

  // Clean up audio context when component unmounts
  useEffect(() => {
    return () => {
      audioContext?.close();
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [audioContext]);

  return (
    <PlayerContainer>
      <VisualizerRoot isPlaying={isPlaying} onClick={togglePlay}>
        <canvas
          ref={canvasRef}
          width={width}
          height={height}
          style={{
            width: `${width}px`,
            height: `${height}px`,
          }}
        />
      </VisualizerRoot>

      <audio ref={audioRef} src="/audio/daniel.mp3" />
    </PlayerContainer>
  );
};

const PlayerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const VisualizerRoot = styled('div').withConfig({
  shouldForwardProp: (prop) => prop !== 'isPlaying',
})<{ isPlaying: boolean }>`
  opacity: ${({ isPlaying }) => (isPlaying ? 1 : 0.5)};
  transition: opacity 0.5s ease-in-out;
  position: relative;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: -1px;
    height: 100%;
    width: 1px;
    background: linear-gradient(
      to bottom,
      transparent,
      ${({ theme }) => rgba(theme.foreground, 0.5)} 35%,
      ${({ theme }) => rgba(theme.foreground, 0.5)} 65%,
      transparent
    );
  }

  &::after {
    left: auto;
    right: -1px;
  }
`;

const Controls = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 4px;
`;

const PlayPauseButton = styled.button`
  background: none;
  border: 1px solid ${({ theme }) => rgba(theme.foreground, 0.5)};
  color: ${({ theme }) => rgba(theme.foreground, 0.8)};
  border-radius: 4px;
  padding: 4px 8px;
  cursor: pointer;
  font-size: 12px;
  transition: all 0.2s ease;

  &:hover {
    background: ${({ theme }) => rgba(theme.foreground, 0.1)};
  }

  &:active {
    transform: scale(0.95);
  }
`;
