'use client';

import {
  Cloud,
  Clouds,
  PerformanceMonitor,
  PerspectiveCamera,
} from '@react-three/drei';
import { Canvas, useFrame } from '@react-three/fiber';
import {
  // @ts-ignore
  Bloom,
  // @ts-ignore
  EffectComposer,
  // @ts-ignore
  Glitch,
  // @ts-ignore
  Noise,
  // @ts-ignore
  Pixelation,
  // @ts-ignore
  Scanline,
} from '@react-three/postprocessing';
import { rgba } from 'polished';
import { GlitchMode } from 'postprocessing';
import { useEffect, useRef, useState } from 'react';
import styled, { css, keyframes, useTheme } from 'styled-components';
import {
  PerspectiveCamera as PerspectiveCameraThree,
  Vector2,
  Vector3,
} from 'three';
import { ChevronLeftIcon, HeadphonesThinIcon } from 'xyz-icon-set-react';
import { useWalkmanStore } from './useWalkmanState';
import { Walkman } from './Walkman';

const Effects = () => {
  return (
    <EffectComposer>
      <Bloom
        intensity={20}
        luminanceThreshold={0}
        luminanceSmoothing={1}
        mipmapBlur={true}
      />
      <Noise opacity={0.05} />
      <Pixelation granularity={2} />
      <Scanline density={1} opacity={0.1} />

      <Glitch
        strength={new Vector2(0, 0.1)}
        mode={GlitchMode.CONSTANT_MILD}
        columns={0.001}
        active
        ratio={0.1}
      />
    </EffectComposer>
  );
};

const Camera = () => {
  const { isDismissed } = useWalkmanStore();
  const cameraRef = useRef<PerspectiveCameraThree>(null);

  const initialPosition = new Vector3(4, 2, 2);
  const finalPosition = new Vector3(2, 1.3, -1);
  const dismissPosition = finalPosition
    .clone()
    .multiplyScalar(1.25)
    .add(new Vector3(0, 0, -2));

  const positionRef = useRef(initialPosition);
  const nextPositionRef = useRef(finalPosition);

  useEffect(() => {
    if (isDismissed) {
      nextPositionRef.current = dismissPosition;
    }
  }, [isDismissed]);

  useFrame(() => {
    if (!cameraRef.current) return;
    cameraRef.current.lookAt(new Vector3(0, 0, 0));

    // use sin to create a randomizer
    const positionRandomizer = Math.sin(Date.now() * 0.001);

    positionRef.current.lerp(nextPositionRef.current, 0.01);
    cameraRef.current.position.copy(positionRef.current);
  });

  return <PerspectiveCamera ref={cameraRef} fov={64} makeDefault={true} />;
};

export const WalkmanIntro = () => {
  const { isDismissed } = useWalkmanStore();
  const [dpr, setDpr] = useState(2);
  const theme = useTheme();

  return (
    <>
      <Canvas dpr={dpr}>
        <PerformanceMonitor
          onIncline={() => setDpr(2)}
          onDecline={() => setDpr(1)}
        />

        <Walkman />

        <Camera />

        <ambientLight intensity={0} />
        <directionalLight position={[10, 10, 10]} intensity={16} />
        <fog attach="fog" args={[theme.background, 0, 3]} />

        <Clouds>
          <Cloud seed={1} segments={10} scale={1} color={theme.background} />
        </Clouds>

        <Effects />
      </Canvas>

      <Info isDismissed={isDismissed}>
        <InfoArrows>
          <ChevronLeftIcon color={theme.foreground} width={12} height={12} />
          <ChevronLeftIcon color={theme.foreground} width={12} height={12} />
        </InfoArrows>

        <InfoIcons>
          <HeadphonesThinIcon color={theme.foreground} />
        </InfoIcons>
      </Info>
    </>
  );
};

const infoFadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const infoFadeOut = keyframes`
  0% { opacity: 1; }
  100% { opacity: 0; }
`;

const infoFadeInMixin = css`
  animation: ${infoFadeIn} 1s forwards;
  animation-delay: 5s;
`;
const infoFadeOutMixin = css`
  animation: ${infoFadeOut} 1s forwards;
  animation-delay: 0;
`;

const Info = styled.div.withConfig({
  shouldForwardProp: (prop) => !['isDismissed'].includes(prop),
})<{ isDismissed: boolean }>`
  position: absolute;
  overflow: visible;
  top: 50%;
  left: 50%;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: -72px;
  opacity: 0;
  padding: 8px;
  padding-right: 16px;
  ${({ isDismissed }) => (isDismissed ? infoFadeOutMixin : infoFadeInMixin)}

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: linear-gradient(
      to right,
      transparent 0%,
      ${({ theme }) => rgba(theme.foreground, 0.2)} 40%,
      ${({ theme }) => rgba(theme.foreground, 0.2)} 60%,
      transparent 100%
    );
  }

  &::after {
    top: auto;
    bottom: 0;
  }
`;

const infoArrowsAnimation = keyframes`
  0% { transform: translateX(0); }
  100% { transform: translateX(-4px); }
`;

const InfoArrows = styled.div`
  display: flex;
  opacity: 0.5;

  :not(:first-child) {
    margin-left: -8px;
  }

  > * {
    animation: ${infoArrowsAnimation} 1s infinite alternate;
    animation-delay: calc(var(--index) * 0.1s);
  }
`;

const InfoIcons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  font-size: 1.25rem;
`;
