import { PropsWithChildren, useId, useMemo, useRef } from 'react';
import { HeartThinIcon } from 'xyz-icon-set-react';
import { useSystemTheme } from '../../ui/hooks/useSystemTheme';

interface HeartRateIndicatorProps extends PropsWithChildren {
  heartRate?: number;
  color?: string;
  size?: number;
  fillOpacity?: number;
}

export const HeartRateIndicator = ({
  heartRate: newHeartRate = 0,
  color: colorOverride,
  size = 12,
  children,
  fillOpacity = 1,
}: HeartRateIndicatorProps) => {
  /**
   * Memo heart rate if it's within 10 BPM of the last value
   * to not disrupt the animation
   */
  const lastHeartRateRef = useRef(+newHeartRate);
  const heartRate = useMemo(() => {
    if (Math.abs(newHeartRate - lastHeartRateRef.current) <= 10) {
      return lastHeartRateRef.current;
    }
    lastHeartRateRef.current = newHeartRate;
    return newHeartRate;
  }, [newHeartRate]);

  const id = useId();
  const theme = useSystemTheme();
  const componentId = `heart-rate-indicator-${id.replace(/:/g, '-')}`;

  const color = colorOverride || theme.accent;

  const magnitude = 0.15;
  const maxScale = 1 + magnitude;
  const restScale = maxScale - magnitude;
  const reboundScale = restScale - magnitude;

  if (!heartRate) {
    return (
      <HeartThinIcon
        fillOpacity={1}
        color={color}
        opacity={1}
        width={size}
        height={size}
        style={{
          maskImage: 'linear-gradient(to bottom, black, rgba(0, 0, 0, 0.5))',
          filter: 'grayscale(1)',
        }}
      />
    );
  }

  return (
    <>
      <div
        style={{
          animation: `${componentId} ${60 / heartRate}s linear infinite`,
        }}
      >
        {children ? (
          children
        ) : (
          <div style={{ fontSize: 0 }}>
            <HeartThinIcon
              fillOpacity={fillOpacity}
              color={color}
              width={size}
              height={size}
              style={{
                maskImage:
                  'linear-gradient(to bottom, black, rgba(0, 0, 0, 0.8))',
              }}
            />
          </div>
        )}
      </div>

      <style>
        {`
        @keyframes ${componentId} {
          0% {
            transform: scale(${restScale});
          }
          15% {
            transform: scale(${maxScale});
          }
          30% {
            transform: scale(${reboundScale});
          }
          45% {
            transform: scale(${restScale});
          }
          100% {
            transform: scale(${restScale});
          }
        }`}
      </style>
    </>
  );
};
